import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Post from "../components/post";

export const query = graphql`
  query ArticleTemplateQuery($id: String!) {
    article: sanityArticle(id: { eq: $id }) {
      id
      title
      subtitle
      poster {
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }
        caption
        source
      }
      posterYoutube
      _rawBody(resolveReferences: { maxDepth: 8 })
      category {
        slug {
          current
        }
        title
        color {
          hex
        }
      }
      tags {
        title
        slug {
          current
        }
      }
    }
  }
`;

const ArticleTemplate = ({ data, pageContext }) => {
  const relatedPosts = pageContext.relatedPosts;

  return (
    <Layout
      className="article"
      backgroundColor={data.article.category?.color.hex}
    >
      <Post
        post={data.article}
        relatedPosts={relatedPosts.slice(0, 3)}
        type="article"
      />
    </Layout>
  );
};

export default ArticleTemplate;
